import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import Button from '../atoms/Button';
import api from '../../services/api';
import COLORS from '../../constants/colors';
import { HasAccess } from '@permify/react-role';
import Modal from './Modal/ModalDelete';
import LoadingScreen from '../../pages/LoadingScreen'
import { useNavigate, useLocation } from 'react-router-dom';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { RiDeleteBinLine } from "react-icons/ri";
import { TiWarning } from "react-icons/ti";
import SCREEN_SIZES from '../../constants/screenSizes';
import { translateOilsType, translateTopicalType } from '../../utils/maps';

function TableMedicamentos(props) {

  const navigate = useNavigate();
  const [medicamentosList, setMedicamentosList] = useState();
  const [loaded, setLoaded] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [userDeleted, setUserDeleted] = useState({});

  const espectros = [
    { value: "completo", label: "Completo" },
    { value: "ampliado", label: "Ampliado" },
    { value: "isolado", label: "Isolado" }
  ]

  const concentracoes = [
    { value: "abaixo_de_50", label: "Abaixo de 50" },
    { value: "de_50_a_100", label: "De 50 a 100" },
    { value: "mais_de_100", label: "Mais de 100" }
  ]
  const administracoes = [
    { value: "oral", label: "Oral" },
    { value: "tópica", label: "Tópica" },
    { value: "transdérmicos", label: "Transdérmicos" },
    { value: "outros", label: "Outros" }
  ]

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 5;

  const [searchEspec, setSearchEspec] = useState();
  const [searchConc, setSearchConc] = useState();
  const [searchAdmin, setSearchAdmin] = useState();
  const [searchName, setSearchName] = useState();
  const [searchCompany, setSearchCompany] = useState();

  const useQueryString = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQueryString();

  const paramNome = query.get('nome') !== null ? query.get('nome') : '';
  const paramEmpresa = query.get('empresa') !== null ? query.get('empresa') : '';
  const paramEspectro = query.get('espectro') !== null ? query.get('espectro') : '';
  const paramConcentracao = query.get('concentracao') !== null ? query.get('concentracao') : '';
  const paramAdministracao = query.get('administracao') !== null ? query.get('administracao') : '';

  const filtered = props.extractType;

  const handleMedicamentosList = (list) => {
    setMedicamentosList(list)
    setCurrentItems(list.slice(0, itemsPerPage))
    setPageCount(Math.ceil(list.length / itemsPerPage))
  }
  const handlePagination = (offset, list) => {
    const endOffset = offset + itemsPerPage
    setCurrentItems(list.slice(offset, endOffset))
  }

  const getMedicamentos = () => {
    if (filtered) {
      api.get(`/medications`, {
        params: {
          extractType: props.extractType,
          primaryCannabinoid: props.primaryCannabinoid,
          secondaryCannabinoid: props.secondaryCannabinoid,
          concentration: props.concentration,
          administrationRoute: props.administrationRoute,
          ...(props.administrationRoute === "oral" && {oralType: props.oralType}),
          ...(props.administrationRoute === "tópica" && {topicalType: props.topicalType}),
        }
      }).then((results) => {
        if (results) {
          handleMedicamentosList(results.data);
        }
        setLoaded(true);
      });
    } else {
      console.log(paramConcentracao)
      api.get('/medications', {
        params: {
          medicationName: paramNome,
          companyName: paramEmpresa,
          extractType: paramEspectro,
          concentration: paramConcentracao,
          administrationRoute: paramAdministracao
        }
      }).then((results) => {
        if (results) {
          handleMedicamentosList(results.data);
        }
        setLoaded(true);
      });
    }
  }

  const handleFilters = () => {
    getMedicamentos()
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage);
    handlePagination(newOffset, medicamentosList);
  };

  const handleDelete = (medicineId, medicine) => {
    setUserDeleted({ id: medicineId, name: medicine })
    setModalConfirm(true);
  }

  useEffect(() => {
    getMedicamentos();
  }, []);

  useEffect(() => {
    let queryString = [];

    if (searchEspec)
      queryString.push(`espectro=${searchEspec}`)

    if (searchConc)
      queryString.push(`concentracao=${searchConc}`)

    if (searchAdmin)
      queryString.push(`administracao=${searchAdmin}`)

    if (searchName)
      queryString.push(`nome=${searchName}`)

    if (searchCompany)
      queryString.push(`empresa=${searchCompany}`)


    navigate(`?${queryString.join('&')}`, { replace: true })
  }, [searchEspec, searchConc, searchAdmin, searchName, searchCompany])


  return (
    <>
      {!loaded &&
        <LoadingScreen loading={!loaded} />
      }
      {loaded &&
        <>
          {modalConfirm &&
            <Modal
              type={'medicamentos'}
              setModalConfirm={setModalConfirm}
              active={modalConfirm}
              title={`Excluir medicamento?`}
              subtitle={`Você tem certeza que quer excluir o medicamento "${userDeleted.name}"?`}
              userId={userDeleted.id}
            >
              <Warning>
                <div>
                  <TiWarning fill={COLORS.blue} size={28} />
                </div>
                <div>
                  <WarningTitle>
                    Atenção
                  </WarningTitle>
                  <WarningText>
                    A exclusão desse medicamento é definitiva.
                  </WarningText>
                </div>
              </Warning>
            </Modal>
          }
          <CardHeader>
            <CardTitle>{props.tableName}</CardTitle>
            {props.concentracao &&
              <Button onClick={() => window.location.reload(false)}>
                Nova Consulta
              </Button>
            }
            <HasAccess
              roles={["Administrador"]}
            >
              {!props.concentracao &&
                <Button onClick={() => navigate('/medicinesRegister', { replace: true })}>
                  Cadastrar Medicamento
                </Button>
              }
            </HasAccess>
          </CardHeader>
          {props.filter &&
            <>
              <Filters>
                <InputGroup>
                  <InputContainer>
                    <Label>
                      Medicamento:
                    </Label>

                    <TextInput
                      onChange={e => {
                        setSearchName(e.target.value)
                      }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label>
                      Empresa:
                    </Label>

                    <TextInput
                      onChange={e => {
                        setSearchCompany(e.target.value)
                      }}
                    />
                  </InputContainer>
                </InputGroup>
              </Filters>
              <Filters>
                <InputGroup>
                  <InputContainer>
                    <Label>
                      Tipo de extrato
                    </Label>
                    <Input
                      onChange={(e) => {
                        if (e.target.value !== 'true') {
                          setSearchEspec(e.target.value)
                        }
                      }}
                      as="select"
                      autoComplete="off"
                      name="espectro"
                    >
                      <Option value="">
                        Selecione o tipo
                      </Option>
                      {espectros &&
                        espectros.map((el) => {
                          return (
                            <Option key={el.value} value={el.value}>{el.label}</Option>
                          )
                        })
                      }
                    </Input>
                  </InputContainer>

                  <InputContainer>
                    <Label>
                      Concentração
                    </Label>
                    <Input
                      onChange={(e) => {
                        if (e.target.value !== 'true') {
                          setSearchConc(e.target.value)
                        }
                      }}
                      as="select"
                      autoComplete="off"
                      name="espectro"
                    >
                      <Option value="">
                        Selecione a concentração
                      </Option>
                      {concentracoes &&
                        concentracoes.map((el) => {
                          return (
                            <Option key={el.value} value={el.value}>{el.label}</Option>
                          )
                        })
                      }
                    </Input>
                  </InputContainer>

                  <InputContainer>
                    <Label>
                      Administração
                    </Label>
                    <Input
                      onChange={(e) => {
                        if (e.target.value !== 'true') {
                          setSearchAdmin(e.target.value)
                        }
                      }}
                      as="select"
                      autoComplete="off"
                      name="espectro"
                    >
                      <Option value="">
                        Selecione a Administração
                      </Option>
                      {administracoes &&
                        administracoes.map((el) => {
                          return (
                            <Option key={el.value} value={el.value}>{el.label}</Option>
                          )
                        })
                      }
                    </Input>
                  </InputContainer>
                  <InputContainer>
                    <Button onClick={(e) => {
                      e.preventDefault();
                      handleFilters()
                    }}>
                      Buscar
                    </Button>
                  </InputContainer>
                </InputGroup>
              </Filters>
            </>
          }

          <Card>
            <CardBody>
              <TableReact>
                <TableHead>
                  <TableRow role="row">
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Medicamento
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Empresa
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Tipo
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Concentração
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Administração
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Tipo
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Tipo de receita
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Cann. Principal
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Cann. Secundário
                    </TableHeader>
                    <HasAccess
                      roles={["Administrador"]}
                    >
                      <TableHeader
                        colspan="2"
                        role="columnheader"
                        title="Toggle SortBy"
                      >
                        Ações
                      </TableHeader>
                    </HasAccess>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loaded && currentItems.length > 0 &&
                    currentItems.map((medicamento) => {
                      return (
                        <TableRow key={medicamento.medication_id}>
                          <TableCell style={{ textTransform: 'capitalize' }}>{medicamento.name}</TableCell>
                          <TableCell style={{ textTransform: 'capitalize' }}>{medicamento.company_name}</TableCell>
                          <TableCell style={{ textTransform: 'capitalize' }}>{medicamento.extract_type}</TableCell>
                          <TableCell>{medicamento.concentrations}</TableCell>
                          <TableCell style={{ textTransform: 'capitalize' }}>{medicamento.administration_route}</TableCell>
                          <TableCell>{(medicamento.topical_type && translateTopicalType(medicamento.topical_type)) || (medicamento.oral_type && translateOilsType(medicamento.oral_type))}</TableCell>
                          <TableCell>{medicamento.prescription_type}</TableCell>
                          <TableCell>{medicamento.primary_cannabinoids}</TableCell>
                          <TableCell>{medicamento.secondary_cannabinoids}</TableCell>

                          <HasAccess roles={["Administrador"]}>
                            <TableCell><RiDeleteBinLine onClick={() => handleDelete(medicamento.id, medicamento.nome)} fill={COLORS.dark} style={{ cursor: 'pointer' }} size={'20px'} /></TableCell>
                          </HasAccess>
                        </TableRow>
                      );
                    })}
                  {loaded && currentItems.length === 0 &&
                    <TableRow>
                      <TableCell>Nenhum medicamento encontrado com os parâmetros informados.</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <HasAccess roles={["Administrador"]}>
                        <TableCell></TableCell>
                      </HasAccess>
                    </TableRow>
                  }
                </TableBody>
              </TableReact>
            </CardBody>
          </Card>
          <Pagination
            breakLabel="..."
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={<HiChevronLeft size={20} fill={COLORS.dark} />}
            nextLabel={<HiChevronRight size={20} fill={COLORS.dark} />}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        </>
      }
    </>
  );
}

const Filters = styled.form`
  width: 100%;
  background: ${COLORS.white};
  border-radius: 0.2857rem;
  padding: 15px;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 6px 10px;
  border-radius: 6px;
  margin-top: 10px;
  margin: 0;
  outline: 0;
  border: 1px solid #d5d5d5;
`

const Option = styled.option`
  padding: 15px;
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 0 20px 0;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
      width: 100%;
  }
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
`;

const WarningTitle = styled.h6`
  margin: 0;
  font-size: 1rem;
  color: ${COLORS.blue};
`;

const WarningText = styled.p`
  font-size: .9rem;
  margin: 0;
  margin-top: 8px;
  color: ${COLORS.blue};

`;

const Warning = styled.div`
  height: 100px;
  width: 100%;
  background: #4C6C8C0F;
  display: block;
  border-radius: 4px;
  border-left: 5px solid ${COLORS.blue};
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Pagination = styled(ReactPaginate).attrs({
  activeClassName: 'active', // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  list-style-type: none;
  padding: 0;
  li a {
    border-radius: 4px;
    padding-inline: .7rem;
    height: 32px;
    background: ${COLORS.white};
    border: 1px solid ${COLORS.softWhite2};
    cursor: pointer;
    display: flex;
    align-items:center;
  }
  li.previous a,
  li.next a,
  li.break a {
  }
  li.active a {
    background-color: ${COLORS.green};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.2857rem;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.softWhite};
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    overflow: auto;
  }
}
`;
const CardHeader = styled.div`
  margin: 15px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardTitle = styled.h4`
  color: ${COLORS.blue};
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: .9rem;
    margin-bottom: 0;
  }
`;

const CardBody = styled.div`
  padding: 15px;
`;

const TableReact = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  color: ${COLORS.dark};
`;

const TableHead = styled.thead`
  width: 100%;
`;

const TableRow = styled.tr`
  width: 100%;
  &:nth-child(even) {
    background-color: ${COLORS.softWhite4};
  }
`;

const TableHeader = styled.th`
  padding: 5px;
  line-height: 2.5em;
  text-align: left;
  font-weight: 500;
  color: ${COLORS.grey};
  font-size: 15px;
  text-overflow: ellipsis;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding-right: 25px;
  }
`;

const TableBody = styled.tbody`
  overflow: auto;
`;

const TableCell = styled.td`
  padding: 12px 7px;
  vertical-allign: middle;
  margin: auto;
  text-overflow: ellipsis;
  border-top: 1px solid ${COLORS.softWhite3};
  color: ${COLORS.dark};
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: .8rem;
    padding: 12px 15px;
  }
`;

export default TableMedicamentos;
