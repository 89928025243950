import React, { useState } from 'react'
import Root from '../components/organisms/Root';
import { HasAccess } from '@permify/react-role';
import styled from 'styled-components';
import COLORS from '../constants/colors';
import Dropzone from 'react-dropzone';
import Button from '../components/atoms/Button';
import api from '../services/api';

function MedicineUpload() {

    const [file, setFile] = useState();

    const handleSend = () => {
        try {
            if (file) {
                api.post('/medicamentos/csv', {
                    file
                })
            } else {
                console.log('error')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Root active={'medicines'}>
            <HasAccess
                roles={["Administrador", "Medico"]}
                renderAuthFailed={<p>Erro: Você não tem permissão para acessar essa tela.</p>}
            >
                <Card>
                    <CardTitle>Cadastro de Medicamentos</CardTitle>
                    <Dropzone accept=".csv" onDrop={acceptedFiles => setFile(acceptedFiles[0])}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <Wrapper active={file} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {file &&
                                        <Text active={true}>Arquivo selecionado: {file.name}</Text>
                                    }
                                    {!!!file &&
                                        <Text>Arraste e solte aqui um arquivo no formato .csv ou <LinkSpan>clique para upload</LinkSpan></Text>
                                    }
                                </Wrapper>
                            </section>
                        )}

                    </Dropzone>
                    <BtnGroup>
                        <Button style={{
                            background: COLORS.softWhite3,
                            color: COLORS.grey,
                            paddingInline: '40px'
                        }} onClick={() => { setFile() }}>Limpar</Button>
                        <Button onClick={() => handleSend()} style={{ paddingInline: '40px' }}>Cadastrar</Button>
                    </BtnGroup>
                </Card>
            </HasAccess >
        </Root>
    )
}

const BtnGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin: 24px 0;
`;

const LinkSpan = styled.span`
    text-decoration: underline;
    font-weight: bold;
    color: #bdbdbd;
`;

const Text = styled.p`
   color: ${props => props.active ? COLORS.blue : '#bdbdbd'};
`;

const Wrapper = styled.div`
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => props.active ? 'transparent' : '#eeeeee'};
    border-style: dashed;
    background-color: ${props => props.active ? '#4C6C8C0F' : '#fafafa'};
    outline: none;
    padding: 20px;
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Card = styled.div`
    max-width: 800px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid ${COLORS.softWhite};
    border-radius: 4px;
    background: ${COLORS.white};
    padding: 16px 30px;
`;

const CardTitle = styled.h4`
    margin-top: 16px;
    font-weight: 500;
    font-size: 1.4rem;
    color: ${COLORS.blue};
`;

export default MedicineUpload