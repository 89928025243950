import React from 'react'
import Root from '../components/organisms/Root';
import { HasAccess } from '@permify/react-role';
import styled from 'styled-components';
import COLORS from '../constants/colors';
import MedicineRegisterWizard from '../components/organisms/MedicineRegisterWizard';

function MedicineRegister() {

    return (
        <Root active={'medicines'}>
            <HasAccess
                roles={["Administrador", "Medico"]}
                renderAuthFailed={<p>Erro: Você não tem permissão para acessar essa tela.</p>}
            >
                <MedicineRegisterWizard />
            </HasAccess >
        </Root>
    )
}

const Card = styled.div`
    max-width: 800px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid ${COLORS.softWhite};
    border-radius: 4px;
    background: ${COLORS.white};
    padding: 16px 30px;
`;

const CardTitle = styled.h4`
    margin-top: 16px;
    font-weight: 500;
    font-size: 1.4rem;
    color: ${COLORS.blue};
`;

export default MedicineRegister;