import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../atoms/Button";
import LoginInput from "../atoms/LoginInput";
import LoginOpt from "../atoms/LoginOptions";
import Cookies from "universal-cookie";
import { Rings } from "react-loader-spinner";
import { usePermify } from "@permify/react-role";
import SCREEN_SIZES from "../../constants/screenSizes";
import api from "../../services/api";

const LoginForm = () => {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();
  const { setUser } = usePermify();

  const Login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api
        .post("/login", {
          usuario: usuario,
          senha: senha,
        })
        .then((res) => {
          setUser({
            id: res.data.usuario.id,
            roles:
              res.data.usuario.profile === 1 ? ["Administrador"] : ["Medico"],
          });

          cookies.set("sechat-token", res.data.token, { path: "/" });
          cookies.set("id", res.data.usuario.id, { path: "/" });
          cookies.set("nome", res.data.usuario.nome, { path: "/" });
          cookies.set("first_access", res.data.usuario.first_access, {
            path: "/",
          });
        })
        .then(() => {
          if (JSON.parse(cookies.get("first_access"))) {
            return (window.location.href = "/mySearch");
          }
          window.location.href = "/";
        });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setMsg("Usuário ou senha inválidos.");
        } else if (error.response.status === 403) {
          console.log(error);
          setMsg(error.response.data.error);
        } else {
          setMsg("Ocorreu um erro. Por favor tente novamente mais tarde.");
        }
      }
      setLoading(false);
    }
  };

  return (
    <DivContainer onSubmit={Login}>
      <TitleContainer>
        <Title>Bem-vindo(a) de volta!</Title>
        <Subtitle>Entre com suas credenciais.</Subtitle>
        <Errors>{msg}</Errors>
      </TitleContainer>
      <LoginInput
        value={usuario}
        onChange={(e) => setUsuario(e.target.value)}
        type="email"
        labeltext="Email"
      />
      <LoginInput
        value={senha}
        onChange={(e) => setSenha(e.target.value)}
        type="password"
        labeltext="Senha"
      />
      <LoginOpt></LoginOpt>
      <Button style={{ marginTop: "16px", width: "100%" }}>
        {!loading && "Login"}
        {loading && (
          <Rings height="35" color="white" ariaLabel="loading" wrapperStyle />
        )}
      </Button>
      <RegisterLink href="/register">
        Novo por aqui? Registre-se agora.
      </RegisterLink>
    </DivContainer>
  );
};

const RegisterLink = styled.a`
  display: block;
  font-size: 14px;
  font-family: "Public Sans", sans-serif;
  color: #7c94a4;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  cursor: pointer;
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

const DivContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
`;

const TitleContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-bottom: 0px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 0;
  font-weight: 600;
  color: #4b4b4b;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.p`
  margin: 0;
  padding: 0;
  color: #7c94a4;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: 0.9rem;
  }
`;

export default LoginForm;
