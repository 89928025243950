import React from 'react';
import TableMedicamentos from '../components/molecules/TableMedicamentos';
import Root from '../components/organisms/Root';
import { HasAccess } from "@permify/react-role";

function Medicines() {
  return (
    <Root active={'medicines'}>
      <HasAccess
        roles={["Administrador", "Medico"]}
        renderAuthFailed={<p>Erro: Você não tem permissão para acessar essa tela.</p>}
      >
        <TableMedicamentos filter={true} tableName={'Medicamentos Cadastrados'} />
      </HasAccess >
    </Root>

  );
}


export default Medicines;
